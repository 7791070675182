.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.logo {
  border: 2px solid #379ea3;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto 0;
  padding: 10px;
}