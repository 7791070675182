.WWord .WLetterz button,
.WWord .WLetterz {
  color: white;
  background-color: #db3eb1;
  border: solid #db3eb1 2px;
  border-radius: 4;
  cursor: default;
  box-shadow: none;
  font-weight: 600;
  width: 20px;
  margin-left: -1px;
  margin-bottom: 0px;
}

.WWord .WLetterz button {
  border: none;
}

.WWord .WLetterz.disabled button,
.WWord .WLetterz.dash {
  color: white;
}

.WWord-container {
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
  display: flex;
  padding-right: 3%;
  padding-left: 0%;
}

@media screen and (max-width: 420px) {
  .WWord-container {
    padding-top: 25px;
    padding-bottom: 10px;
    justify-content: center;
    display: flex;
    padding-right: 9%;
    padding-left: 0%;
  }
}