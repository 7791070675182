/* * {
  margin: 0;
  padding: 0;
  font-size: xx-large;
}


body {
  padding: 0.5rem;
  font-size: larger;
}

@media only screen and (max-width: 600px) {
  body {
    padding: 0.1rem;
  }
}

footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

@media only screen and (max-width: 600px) {
  footer {
    margin: auto;
    width: 50%;
  }
}
*/

.footer .fa {
  color: white;
  float: left;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.footer {
  display: inline-block;
  border-radius: 10px;
  margin-top: 1em;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
} 

.ph3-main-bg {
  background-color: orange;
  width: 100%;
  max-width: 480px;
  height: 95vh;  
  padding-top: 0px;
  overflow: hidden,
}

.topic-main-image {
  width: 100%;
  height: 95vh; 
  max-width: 480px; 
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.ph2answers {
  max-width: 480px;
  position: absolute;
  top: 82%;
  width: 100%;
  left: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ph2answer {
  width: 40%;
  padding: 10px;
  margin: 0 10px 20px 10px;
  text-align: center;
  background: linear-gradient(#0e0124, #22074d);
  border: 1px solid white;
  border-radius: 15px;
  font-weight: 300;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  color: white;
}

.ph2outcome {
  max-width: 480px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 75%;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.ph2choose {
  max-width: 480px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 98%;
  font-size: 16px;
  font-weight: 600;
  color: white
}

.ph3Input {
  /* border: 2px solid red; */
  border-radius: 4px;
  width: 75%;
  height: 40px;
  font-size: 20px;
  text-align: center;
}

.ph3InputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}