.WLetterz {
  border-radius: 10%;
  margin: 3px;
  height: 30px;
  width: 25px;
  text-align: center;
  list-style-type: none;
  display: inline-block;
  color: white;
  font-size: small;
  padding-top: 3px;
  /* background-color: red; */

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5),
    /* Exterior Shadow */ inset 0 1px rgba(255, 255, 255, 0.3),
    /* Top light Line */ inset 0 10px rgba(255, 255, 255, 0.2),
    /* Top Light Shadow */ inset 0 10px 20px rgba(255, 255, 255, 0.25),
    /* Sides Light Shadow */ inset 0 -15px 30px rgba(0, 0, 0, 0.1); /* Dark Background */
}

.WLetterz button,
.WLetterz span {
  color: white;
  background: transparent;
  border: none;
}

.WLetterz span {
  user-select: none;
}

.Letter.disabled {
  box-shadow: none;
}

/* .dash {
  border: none !important;
} */

.space {
  border: none !important;
  display: block;
  background-color: transparent;
  height: 0;
  padding: 0;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .WLetterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}

@media screen and (max-width: 420px) {
  .WLetterz {
    margin: 1vw;
    width: 8vw;
    min-width: 25px;
    font-size: 13px;
  }
}