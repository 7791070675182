.Alphabet .Letterz {
  cursor: pointer;
  font-weight: 600;
  background-color: red;
  padding-top: 4px;  
  font-size: 13px;
}

.Alphabet .Letterz button {
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
}

@media screen and (min-width: 1200px) {
  .Alphabet .Letterz:hover {
    transition: all 0.2s;
    transform: rotate(-3deg) scale(1.1);
  }
}

.Alphabet .Letterz.disabled {
  background-color: transparent;
  cursor: not-allowed;
  transform: none;
}

.Alphabet button[disabled] {
  cursor: not-allowed;
}

.Alphabet {
  background-color: orange;
  padding-top: 15px;
  padding-bottom: 20px;
  padding-right: 6%;
  padding-left: 6%;
  justify-content: center;
  align-items: center;
  display: flexbox;
  
}

@media screen and (max-width: 600px) {
  .Alphabet .Letterz {
    padding-right: 28px;
    font-size: 13px;
  }

  .Alphabet {
    padding-right: 0vw;
    padding-left: 1vw;
  }
}

@media screen and (max-width: 420px) {
  .Alphabet .Letterz {
    /* padding-right: 30px; */
    padding-right: 35px;
    padding-top: 4px;  
  }

  .Alphabet {
    padding-right: 0vw;
    padding-left: 1vw;
  }
}