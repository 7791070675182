.numberbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: "Arial", sans-serif;
  font-size: 21px;
  height: 60vh;
}
.text {
  order: 2;
  padding-top: 40px;
  width: 330px;
  font-weight: bold;
}
.board {
  order: 1;
  width: 330px;
  height: 330px;
  padding: 5px;
  background-color: #baa;
  border-radius: 7px;
  outline: none;
  position: relative;
}
.board .cell,
.tile {
  user-select: none;
  cursor: default;
}
.cell,
.tile {
  width: 70px;
  height: 70px;
  margin: 5px;
  line-height: 70px;
  display: inline-block;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
  font-family: "Arial";
  color: #766;
  background-color: #dcb;
}
.tile0 {
  background-color: #dcb;
}
.tile2 {
  background-color: #eee;
}
.tile4 {
  background-color: #eec;
}
.tile8 {
  color: #ffe;
  background-color: #fb8;
}
.tile16 {
  color: #ffe;
  background-color: #f96;
}
.tile32 {
  color: #ffe;
  background-color: #f75;
}
.tile64 {
  color: #ffe;
  background-color: #f53;
}
.tile128 {
  color: #ffe;
  background-color: #ec7;
  font-size: 30px;
}
.tile256 {
  color: #ffe;
  background-color: #ec6;
  font-size: 30px;
}
.tile512 {
  color: #ffe;
  background-color: #ec5;
  font-size: 30px;
}
.tile1024 {
  color: #fff;
  background-color: #ec3;
  font-size: 25px;
}
.tile2048 {
  color: #fff;
  background-color: #ec2;
  font-size: 25px;
}
.tile {
  position: absolute;
}
.tile.merged {
  display: none;
}
.tile.merged.isMoving {
  display: inline;
}
.tile.new,
.overlay {
  animation-duration: 0.2s;
  animation-name: newTile;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
  transform: scale(0);
}
@keyframes newTile {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 0px;
  right: 0px;
  font-size: 55px;
  font-weight: bolder;
  background-color: rgba(221, 221, 221, 0.5);
  border-radius: 7px;
}
.tryAgain {
  background-color: #876;
  color: #fff;
  height: 40px;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
.overlay .message {
  color: #666;
}

.position_0_0:not(.isMoving) {
  top: 5px;
  left: 5px;
}
.position_0_1:not(.isMoving) {
  top: 5px;
  left: 85px;
}
.position_0_2:not(.isMoving) {
  top: 5px;
  left: 165px;
}
.position_0_3:not(.isMoving) {
  top: 5px;
  left: 245px;
}
.position_1_0:not(.isMoving) {
  top: 85px;
  left: 5px;
}
.position_1_1:not(.isMoving) {
  top: 85px;
  left: 85px;
}
.position_1_2:not(.isMoving) {
  top: 85px;
  left: 165px;
}
.position_1_3:not(.isMoving) {
  top: 85px;
  left: 245px;
}
.position_2_0:not(.isMoving) {
  top: 165px;
  left: 5px;
}
.position_2_1:not(.isMoving) {
  top: 165px;
  left: 85px;
}
.position_2_2:not(.isMoving) {
  top: 165px;
  left: 165px;
}
.position_2_3:not(.isMoving) {
  top: 165px;
  left: 245px;
}
.position_3_0:not(.isMoving) {
  top: 245px;
  left: 5px;
}
.position_3_1:not(.isMoving) {
  top: 245px;
  left: 85px;
}
.position_3_2:not(.isMoving) {
  top: 245px;
  left: 165px;
}
.position_3_3:not(.isMoving) {
  top: 245px;
  left: 245px;
}
.row_from_0_to_0 {
  top: 5px;
}
.row_from_0_to_1 {
  animation-duration: 0.2s;
  animation-name: row_from_0_to_1;
  animation-fill-mode: forwards;
}
@keyframes row_from_0_to_1 {
  from {
    top: 5px;
  }
  to {
    top: 85px;
  }
}
.row_from_0_to_2 {
  animation-duration: 0.2s;
  animation-name: row_from_0_to_2;
  animation-fill-mode: forwards;
}
@keyframes row_from_0_to_2 {
  from {
    top: 5px;
  }
  to {
    top: 165px;
  }
}
.row_from_0_to_3 {
  animation-duration: 0.2s;
  animation-name: row_from_0_to_3;
  animation-fill-mode: forwards;
}
@keyframes row_from_0_to_3 {
  from {
    top: 5px;
  }
  to {
    top: 245px;
  }
}
.row_from_1_to_0 {
  animation-duration: 0.2s;
  animation-name: row_from_1_to_0;
  animation-fill-mode: forwards;
}
@keyframes row_from_1_to_0 {
  from {
    top: 85px;
  }
  to {
    top: 5px;
  }
}
.row_from_1_to_1 {
  top: 85px;
}
.row_from_1_to_2 {
  animation-duration: 0.2s;
  animation-name: row_from_1_to_2;
  animation-fill-mode: forwards;
}
@keyframes row_from_1_to_2 {
  from {
    top: 85px;
  }
  to {
    top: 165px;
  }
}
.row_from_1_to_3 {
  animation-duration: 0.2s;
  animation-name: row_from_1_to_3;
  animation-fill-mode: forwards;
}
@keyframes row_from_1_to_3 {
  from {
    top: 85px;
  }
  to {
    top: 245px;
  }
}
.row_from_2_to_0 {
  animation-duration: 0.2s;
  animation-name: row_from_2_to_0;
  animation-fill-mode: forwards;
}
@keyframes row_from_2_to_0 {
  from {
    top: 165px;
  }
  to {
    top: 5px;
  }
}
.row_from_2_to_1 {
  animation-duration: 0.2s;
  animation-name: row_from_2_to_1;
  animation-fill-mode: forwards;
}
@keyframes row_from_2_to_1 {
  from {
    top: 165px;
  }
  to {
    top: 85px;
  }
}
.row_from_2_to_2 {
  top: 165px;
}
.row_from_2_to_3 {
  animation-duration: 0.2s;
  animation-name: row_from_2_to_3;
  animation-fill-mode: forwards;
}
@keyframes row_from_2_to_3 {
  from {
    top: 165px;
  }
  to {
    top: 245px;
  }
}
.row_from_3_to_0 {
  animation-duration: 0.2s;
  animation-name: row_from_3_to_0;
  animation-fill-mode: forwards;
}
@keyframes row_from_3_to_0 {
  from {
    top: 245px;
  }
  to {
    top: 5px;
  }
}
.row_from_3_to_1 {
  animation-duration: 0.2s;
  animation-name: row_from_3_to_1;
  animation-fill-mode: forwards;
}
@keyframes row_from_3_to_1 {
  from {
    top: 245px;
  }
  to {
    top: 85px;
  }
}
.row_from_3_to_2 {
  animation-duration: 0.2s;
  animation-name: row_from_3_to_2;
  animation-fill-mode: forwards;
}
@keyframes row_from_3_to_2 {
  from {
    top: 245px;
  }
  to {
    top: 165px;
  }
}
.row_from_3_to_3 {
  top: 245px;
}
.column_from_0_to_0 {
  left: 5px;
}
.column_from_0_to_1 {
  animation-duration: 0.2s;
  animation-name: column_from_0_to_1;
  animation-fill-mode: forwards;
}
@keyframes column_from_0_to_1 {
  from {
    left: 5px;
  }
  to {
    left: 85px;
  }
}
.column_from_0_to_2 {
  animation-duration: 0.2s;
  animation-name: column_from_0_to_2;
  animation-fill-mode: forwards;
}
@keyframes column_from_0_to_2 {
  from {
    left: 5px;
  }
  to {
    left: 165px;
  }
}
.column_from_0_to_3 {
  animation-duration: 0.2s;
  animation-name: column_from_0_to_3;
  animation-fill-mode: forwards;
}
@keyframes column_from_0_to_3 {
  from {
    left: 5px;
  }
  to {
    left: 245px;
  }
}
.column_from_1_to_0 {
  animation-duration: 0.2s;
  animation-name: column_from_1_to_0;
  animation-fill-mode: forwards;
}
@keyframes column_from_1_to_0 {
  from {
    left: 85px;
  }
  to {
    left: 5px;
  }
}
.column_from_1_to_1 {
  left: 85px;
}
.column_from_1_to_2 {
  animation-duration: 0.2s;
  animation-name: column_from_1_to_2;
  animation-fill-mode: forwards;
}
@keyframes column_from_1_to_2 {
  from {
    left: 85px;
  }
  to {
    left: 165px;
  }
}
.column_from_1_to_3 {
  animation-duration: 0.2s;
  animation-name: column_from_1_to_3;
  animation-fill-mode: forwards;
}
@keyframes column_from_1_to_3 {
  from {
    left: 85px;
  }
  to {
    left: 245px;
  }
}
.column_from_2_to_0 {
  animation-duration: 0.2s;
  animation-name: column_from_2_to_0;
  animation-fill-mode: forwards;
}
@keyframes column_from_2_to_0 {
  from {
    left: 165px;
  }
  to {
    left: 5px;
  }
}
.column_from_2_to_1 {
  animation-duration: 0.2s;
  animation-name: column_from_2_to_1;
  animation-fill-mode: forwards;
}
@keyframes column_from_2_to_1 {
  from {
    left: 165px;
  }
  to {
    left: 85px;
  }
}
.column_from_2_to_2 {
  left: 165px;
}
.column_from_2_to_3 {
  animation-duration: 0.2s;
  animation-name: column_from_2_to_3;
  animation-fill-mode: forwards;
}
@keyframes column_from_2_to_3 {
  from {
    left: 165px;
  }
  to {
    left: 245px;
  }
}
.column_from_3_to_0 {
  animation-duration: 0.2s;
  animation-name: column_from_3_to_0;
  animation-fill-mode: forwards;
}
@keyframes column_from_3_to_0 {
  from {
    left: 245px;
  }
  to {
    left: 5px;
  }
}
.column_from_3_to_1 {
  animation-duration: 0.2s;
  animation-name: column_from_3_to_1;
  animation-fill-mode: forwards;
}
@keyframes column_from_3_to_1 {
  from {
    left: 245px;
  }
  to {
    left: 85px;
  }
}
.column_from_3_to_2 {
  animation-duration: 0.2s;
  animation-name: column_from_3_to_2;
  animation-fill-mode: forwards;
}
@keyframes column_from_3_to_2 {
  from {
    left: 245px;
  }
  to {
    left: 165px;
  }
}
.column_from_3_to_3 {
  left: 245px;
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}