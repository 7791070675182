.GuessesLeft {
  text-align: center;
  display: inline-flex;
  color: white;
  cursor: default;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
}

.GuessContainer {
  width: 100%;
  min-width: 480px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .GuessesLeft {
    width: 100%;
    min-width: 360px;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 6%;
  }
  .GuessContainer {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
