footer {
  position:absolute;
  left:0;
  bottom:0;
  right:0;

}

.gameOverText {

}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  margin-top: 80px;
  line-height: 1.5em;
  letter-spacing: .1em;
  font-size: 40px;
  color: #fff;
  text-align: center;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #0e0124;
}
.big{
  text-transform: uppercase;
  font-size: 50px;
  color: rgb(173, 106, 17);
  text-decoration: underline wavy rgb(196, 196, 207) 5px;

}

.copyRight{
    position: absolute;
    width: 100%;
    color: #fff;
    line-height: 40px;
    font-size: 1rem;
    text-align: center;
    bottom:0;
}

.startScreen {
  position: relative;
  height: 100vh;
  display: flex;
  color: white;
  background-color: #020230;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;

}

.app {
  display: flex;
  justify-content: center;
  /* background-color: #020230; */
  color: white;
}

.main {
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #19191f),
    url("https://wallpaperaccess.com/full/2384073.jpg") center;  */
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  max-width: 480px;
  height: 95vh;  
  padding-top: 20px;
  overflow: hidden,
}

.pyramid {
  background-color: #020230;

}

.moneyList {
  padding: 20px;
  width: 100%;
}

.moneyListItem {
  padding: 5px;
  border-radius: 5px;
  border-style: groove;
  border-color: aliceblue;
  border-width: medium;
}

.moneyListItem.active {
  background-color: teal;
}

.moneyListItemNumber {
  font-size: 18px;
  font-weight: 100;
}

.moneyListItemAmount {
  font-size: 20px;
  font-weight: 300;
}

.top {
  height: 20%;
  position: relative;
  display: flex;
  justify-content: center;
}

.used{
  text-decoration: line-through;
  font-weight: 100;
  color: red;
}

.phtimer {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 5px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  font-size: 30px;
  font-weight: 700;
}

.change {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 180px;
  font-size: 30px;
  font-weight: 700;
}

.double {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 280px;
  font-size: 20px;
  font-weight: 700;
}

.fifty {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 280px;
  font-size: 20px;
  font-weight: 700;
}

.bottom {
  height: 200%;
}

.questionnaire {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.question1 {
  width: 80%;
  height: 280px;
  background: linear-gradient(#ffcaca, #ffcaca);
  color: #404040;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #dc2828;
  font-size: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25%;
  max-width: 384px;
}

.phor {
  max-width: 480px;
  position: absolute;
  top: 30%;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
}

.answers {
  max-width: 480px;
  position: absolute;
  top: 82%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.phanswer {
  width: 80%;
  padding: 10px;
  margin: 0 10px 20px 10px;
  text-align: center;
  background: #018cd3;
  border: 1px solid white;
  border-radius: 15px;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  color: black;
}

/* .answer:hover{
  background: mediumblue;
} */

.phanswer.correct {
  animation: correct 0.1s ease forwards;
}

@keyframes correct {

  /* 2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%, */
  1%, 100% {
    background: #10d115;
  }
}

.phanswer.wrong {
  animation: wrong 0.1s ease forwards;
}

@keyframes wrong {

  /* 2%,
  12%,
  27% {
    background: mediumblue;
  }

  10%,
  25%,
  40% {
    background: linear-gradient(#0e0124, #22074d);
  }

  42%, */
  1%, 100% {
    background: crimson;
  }
}

.timesup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}


.start {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.startInput {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: black;
}

.startInput:focus {
  outline: none;
}

.startButton {
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
}

.guessfooter {
  position: absolute;
  top: 70%;
  width: 100%;
  max-width: 480px;
  height: 60px;
  align-items: center;
  justify-content: center;
  /* display: flex;
  flex-direction: row; */
}

.tutorialImage {
  width: 100%;
  height: 95%;
  object-fit: contain;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
  max-width: 480px;
}

.close {
  position: relative;
  top: -40%;
  right: 20px;
  color: white;
  font-size: 16;
}

.clue {
  padding: 10px;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}

.phchoose {
  max-width: 480px;
  position: absolute;
  top: 98%;
  font-size: 16px;
  font-weight: 600;
  color: 'white'
}

.clues {
  max-width: 480px;
  position: absolute;
  top: 20%;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.phoutcome {
  max-width: 480px;
  position: absolute;
  top: 97%;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.payIcon {
  width: 36px;
  height: 36px;
  object-fit: cover;
  position: absolute;
  right: 25px;
  top: 0px;
  cursor: pointer;
}