.wallect-connect-login_cardBody #accessWalletBtn {
  background: #000;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}
.wallect-connect-login_cardBody #accessWalletBtn svg {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}
.wallect-connect-login_cardBody #accessWalletBtn svg image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wallect-connect-login_cardBody #accessWalletBtn svg use {
  transform: scale(1);
}

.wallect-connect-login_loginText {
  font-size: 14px;
}

.bg_connect_maiar_btn {
  margin-top: 30px;
}
.bg_connect_maiar_btn button {
  font-size: 14px;
  color: #000000 !important;
  display: block !important;
  position: relative;
  padding-left: 65px !important;
  max-width: 250px;
  width: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto !important ;
}
.bg_connect_maiar_btn button::before {
  content: "";
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
  width: 21px;
  height: 20px;
  background-image: url(../../assets/images/logo/maiar.png);
  background-size: 21px 20px;
}