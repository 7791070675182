.Word .Letterzph3 button,
.Word .Letterzph3 {
  color: white;
  background-color: #334456;
  border: solid white 1px;
  border-radius: 4;
  cursor: default;
  box-shadow: none;
  font-weight: 600;
  width: 20px;
  margin-left: -1px;
  margin-bottom: 10px;
}

.Word .Letterzph3 button {
  border: none;
}

.Word .Letterzph3.disabled button,
.Word .Letter.dash {
  color: white;
}

.word-container {
  padding-top: 25px;
  padding-bottom: 20px;
  justify-content: center;
  display: flex;
  padding-right: 3%;
  padding-left: 0%;
}

@media screen and (max-width: 420px) {
  .word-container {
    padding-top: 25px;
    padding-bottom: 20px;
    justify-content: center;
    display: flex;
    padding-right: 9%;
    padding-left: 0%;
  }
}