.Topic-container {
  position: relative;
  max-width: 480px;
  height: 100%;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.not-scrollable {
  overflow: hidden;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
}

.ph3timer {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 5px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80px;
  left: 20px;
  font-size: 30px;
  font-weight: 700;
  background-color: white;
}