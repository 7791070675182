.lottoroot {
	/* max-width: 480px;
	margin: 0 auto;
	width: 100%;
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
	padding-bottom: 100px; */
	position: relative;
  max-width: 480px;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: flex-start;
  height: 88vh;
  overscroll-behavior-y: none;
  overflow: hidden;
}