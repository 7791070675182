.CategoryContainer {
  border: 5px solid white;
  width: 80%;
  border-radius: 12px;
  height: 150px;
  position: relative;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.CategoryImage {
  width: 100%;
  height: 146px;
  object-fit: stretch;
  position: absolute;
  margin-top: 0px;
  border-radius: 12px;
  z-index: 1;
}

.Category {
  /* text-transform: uppercase; */
  color: white;
  font-size: large;
  text-align: center;
  z-index: 1;
  padding: 5px;
}

@media only screen and (max-width: 420px) {
  .Category {
    font-size: medium;
    font-weight: 400;
    padding: 5px;
  }
  .CategoryImage {
    left: 0%;
    width: 100%;
  }
}
