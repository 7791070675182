.ClueContainer {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 65px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.SettingText {
  font-size: 24px;
  text-align: center;
  color: yellow;
  font-weight: 600;
  top: -40px;
  width: 100%;
  text-align: center;
  position: absolute;
}

.Clue {
  /* text-transform: uppercase; */
  font-size: 17px;
  font-weight: 400;
  margin-top: 165px;
  width: 90%;
  text-align: center;
  position: absolute;
  color: white;
  z-index: 1;
}

@media only screen and (max-width: 420px) {
  .Clue {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    color: white;
    top: 0px;
  }
  .TipImage {
    left: 0%;
    width: 100%;
  }
}
