.wheelroot {
	max-width: 480px;
	margin: 0 auto;
	/* background-image: url('images/background.jpg'); */
	width: 100%;
	background-size: 100% auto;
	background-repeat: no-repeat;
	overflow: hidden;
}

.button2 {
	margin-top: 20px;
	height: 40px;
	width: 150px;
	border-radius: 8px;
}

.numContainer {
	margin-top: 20px;
	padding-top: 15px;
  padding-bottom: 20px;
  padding-right: 6%;
  padding-left: 6%;
  justify-content: center;
  align-items: center;
  display: flexbox;
}

.lottobutton {
	cursor: pointer;
  background-color: lightgray;
  text-align: center;
  font-size: 20px;
	padding: 0.4rem 0 2rem 0;
	width: 42px;
  height: 42px;
	color: black;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: 3px;
}

.lottoStart {
  padding: 1.7rem 0 2rem 0;
	cursor: pointer;
  background-color: lightgray;
  height: 90px;
  width: 90px;
  position: absolute;
  border-radius: 50%;
  background-color: orange;
  border: 6px solid yellow;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.winContainer {
  height: 80px;
  border: 6px solid white;
  border-radius: 40px;
  width: 320px;
  background-color: darkgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yourNumberContainer {
  height: 80px;
  border: 6px solid white;
  border-radius: 40px;
  width: 320px;
  background-color: darkgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yourNumbersContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yourNum {
  font-size: 24px;
	height: 40px;
  padding: 0.5rem 0 2rem 0;
	text-align: center;
  font-weight: 600;
	color: lightgoldenrodyellow;
  text-shadow: 1px 1px 2px white;
}

.selNum {
  padding: 1rem 0 2rem 0;
  font-size: 18px;
	height: 40px;
  text-align: center;
  font-weight: 600;
	color: black;
  position: absolute;
}

.selNum2 {
  padding: 1.25rem 0 2rem 0;
  font-size: 18px;
	height: 40px;
  text-align: center;
  font-weight: 600;
	color: black;
  position: absolute;
}